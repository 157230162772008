import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Module } from "./module";
import { PricingModel } from "./pricing-model";

export class PricingModelModule extends BaseEntity<PricingModelModule> {
    public pricingModelModuleId!: number;
    public pricingModelId!: number;
    public moduleId!: number;
    public monthlyFeeDollars!: number;

    public pricingModel!: PricingModel;
    public module!: Module;
}

export const PricingModelModuleBreezeModel = new BreezeModelBuilder("PricingModelModule", PricingModelModule)
    .hasSource()
    .build();
