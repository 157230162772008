import { Injectable, Injector } from "@angular/core";
import { Connection, ConnectionBreezeModel } from "@common/ADAPT.Common.Model/organisation/connection";
import { Person, PersonBreezeModel } from "@common/ADAPT.Common.Model/person/person";
import { PersonContactBreezeModel } from "@common/ADAPT.Common.Model/person/person-contact";
import { PersonDetailBreezeModel } from "@common/ADAPT.Common.Model/person/person-detail";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { BaseService } from "@common/service/base.service";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class PeopleService extends BaseService {
    constructor(
        injector: Injector,
    ) {
        super(injector);
    }

    public getPersonById(personId: number, forceRemote = false) {
        const predicate = new MethodologyPredicate<Person>("personId", "==", personId);

        return this.commonDataService.getWithOptions(PersonBreezeModel,
            predicate.getKey(PersonBreezeModel.identifier),
            {
                predicate,
                navProperty: "methodologyUser, personDetails",
                forceRemote,
            },
        ).pipe(
            map(ArrayUtilities.getSingleFromArray),
        );
    }

    public getAllPeople = (forceRemote?: boolean) => this.commonDataService.getWithOptions(PersonBreezeModel, "getAllPeople", {
        navProperty: "methodologyUser",
        forceRemote,
    });

    public getAllPersonDetails = (forceRemote?: boolean) => this.commonDataService.getAll(PersonDetailBreezeModel, forceRemote);
    public getAllPersonContacts = (forceRemote?: boolean) => this.commonDataService.getAll(PersonContactBreezeModel, forceRemote);
    public getAllConnections = (forceRemote?: boolean) => this.commonDataService.getAll(ConnectionBreezeModel, forceRemote);

    public getPeopleForOrganisationId(organisationId: number) {
        const connectionPredicate = new MethodologyPredicate<Connection>("organisationId", "==", organisationId);
        const personPredicate = new MethodologyPredicate<Person>("connections", "any", connectionPredicate);

        return this.commonDataService.getWithOptions(PersonBreezeModel, personPredicate.getKey(PersonBreezeModel.identifier), {
            navProperty: "methodologyUser",
            predicate: personPredicate,
        });
    }
}
