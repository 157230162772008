import { ApplicationRef, DoBootstrap, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AdaptIdentityModule } from "@common/identity/identity.module";
import { AdaptCommonModule } from "@common/lib/common.module";
import { AdaptCommonDataModule } from "@common/lib/data/common-data.module";
import { AdaptCommonPagesModule } from "@common/lib/pages/pages.module";
import { AdaptReleaseNotifierModule } from "@common/lib/release-notifier/release-notifier.module";
import { AdaptCommonRouteModule } from "@common/route/common-route.module";
import { provideDynamicNodeBuilder } from "@common/route/dynamic-node-builder";
import { provideNavigationHierarchy } from "@common/route/navigation-hierarchy";
import { provideUserMenuItem } from "@common/shell/application-bar-user-item-content/user-menu-item";
import { provideSidebarTab } from "@common/shell/common-sidebar/sidebar-tab";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptCommonUserModule } from "@common/user/adapt-common-user.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptChangeManagerModule } from "@common/ux/change-manager/change-manager.module";
import { AdaptDocumentSelectorModule } from "@common/ux/document-selector/document-selector.module";
import { AdaptDeepDiveModule } from "app/features/workflow/deep-dive/deep-dive.module";
import { DxDataGridModule } from "devextreme-angular";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { AdaptCoachModule } from "./features/coaches/coach.module";
import { AdaptOrganisationsModule } from "./features/organisations/organisations.module";
import { AdaptPartnerModule } from "./features/partners/partner.module";
import { AdaptPeopleModule } from "./features/people/people.module";
import { AdaptToolsModule } from "./features/tools/tools.module";
import { LandingPageComponent } from "./services/landing-page/landing-page.component";
import { NimbusNavigationService } from "./services/nimbus-navigation.service";
import { NimbusShellWrapperComponent } from "./services/nimbus-shell-wrapper/nimbus-shell-wrapper.component";
import { NimbusSidebar } from "./services/nimbus-sidebar";
import { NimbusSidebarContentComponent } from "./services/nimbus-sidebar-content/nimbus-sidebar-content.component";
import { NimbusSwitchToAltoUserMenuItem } from "./services/nimbus-switch-to-alto-user-menu-item";
import { NimbusSwitchToEmbedUserMenuItem } from "./services/nimbus-switch-to-embed-user-menu-item";
import { AdaptTrackingModule } from "./services/tracking.module";

@NgModule({
    declarations: [
        AppComponent,
        NimbusShellWrapperComponent,
        NimbusSidebarContentComponent,
        LandingPageComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        AppRoutingModule,
        AdaptCommonModule.forRoot(),
        AdaptCommonDataModule,
        AdaptTrackingModule.forRoot(),
        AdaptDocumentSelectorModule.forRoot(),
        AdaptToolsModule,
        AdaptOrganisationsModule,
        DxDataGridModule,
        AdaptChangeManagerModule,
        AdaptIdentityModule,
        AdaptPartnerModule,
        AdaptCoachModule,
        AdaptPeopleModule,
        AdaptButtonModule,
        AdaptShellModule,
        AdaptCommonDialogModule,
        AdaptCommonUserModule,
        AdaptReleaseNotifierModule,
        AdaptCommonRouteModule,
        AdaptDeepDiveModule,
        AdaptCommonPagesModule,
    ],
    providers: [
        provideSidebarTab(NimbusSidebar),
        provideDynamicNodeBuilder(NimbusNavigationService),
        provideNavigationHierarchy(NimbusNavigationService),
        provideUserMenuItem(NimbusSwitchToEmbedUserMenuItem),
        provideUserMenuItem(NimbusSwitchToAltoUserMenuItem),
    ],
})
export class AppModule implements DoBootstrap {
    public ngDoBootstrap(app: ApplicationRef) {
        app.bootstrap(AppComponent);
    }
}
