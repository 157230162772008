import { Injectable, Injector } from "@angular/core";
import { Account, AccountBreezeModel } from "@common/ADAPT.Common.Model/account/account";
import { CurrencyBreezeModel } from "@common/ADAPT.Common.Model/embed/currency";
import { ModuleBreezeModel } from "@common/ADAPT.Common.Model/embed/module";
import { PricingModel, PricingModelBreezeModel, ProductCode } from "@common/ADAPT.Common.Model/embed/pricing-model";
import { PricingModelModuleBreezeModel } from "@common/ADAPT.Common.Model/embed/pricing-model-module";
import { PricingModelUserBreezeModel } from "@common/ADAPT.Common.Model/embed/pricing-model-user";
import { Organisation, OrganisationBreezeModel } from "@common/ADAPT.Common.Model/organisation/organisation";
import { OrganisationSupplementaryData, OrganisationSupplementaryDataBreezeModel } from "@common/ADAPT.Common.Model/organisation/organisation-supplementary-data";
import { Practitioner, PractitionerBreezeModel } from "@common/ADAPT.Common.Model/practitioner/practitioner";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { RouteService } from "@common/route/route.service";
import { BaseService } from "@common/service/base.service";
import { defer, lastValueFrom, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { organisationPageRoute } from "./organisation-page/organisation-page.route";

@Injectable({
    providedIn: "root",
})
export class OrganisationsService extends BaseService {
    constructor(
        injector: Injector,
        private routeService: RouteService,
    ) {
        super(injector);
    }

    public getOrganisations = (forceRemote?: boolean) => this.commonDataService.getAll(OrganisationBreezeModel, forceRemote);
    public getOrganisationSupplementaryData = (forceRemote?: boolean) => this.commonDataService.getAll(OrganisationSupplementaryDataBreezeModel, forceRemote);
    public getCurrencies = (forceRemote?: boolean) => this.commonDataService.getAll(CurrencyBreezeModel, forceRemote);

    public getRouteToOrganisationDetailPage(organisation: Organisation) {
        const params = {
            organisationUrlIdentifier: organisation.urlIdentifier,
        };

        return defer(() => this.routeService.getControllerRoute(organisationPageRoute.id, params));
    }

    public getOrganisationEmbedUrl(organisation: Organisation) {
        if (!organisation) {
            return "";
        }

        return `${AdaptClientConfiguration.FrontEndBaseUri}/${organisation.urlIdentifier}/dashboard`;
    }

    public async getOrganisationByUrlIdentifier(urlIdentifier: string) {
        const predicate = new MethodologyPredicate<Organisation>("urlIdentifier", "==", urlIdentifier);

        const organisations: Organisation[] = await lastValueFrom(this.commonDataService.getByPredicate(OrganisationBreezeModel, predicate));
        return ArrayUtilities.getSingleFromArray(organisations);
    }

    public getOrganisationsForCoachId(coachId: number) {
        return this.commonDataService.getById(PractitionerBreezeModel, coachId).pipe(
            switchMap((coach) => {
                if (!coach) {
                    return of([]);
                }

                const organisationIds = coach.person.connections
                    .filter((connection) => connection.isCoachConnection() && connection.isActive())
                    .map((connection) => connection.organisationId);

                const predicate = new MethodologyPredicate<Organisation>("organisationId", "in", organisationIds);
                return this.commonDataService.getByPredicate(OrganisationBreezeModel, predicate);
            }),
        );
    }

    public getEmbedLinkForOrganisation(org: Organisation) {
        if (!org) {
            return undefined;
        }

        return `${AdaptClientConfiguration.FrontEndBaseUri}/${org.urlIdentifier}/dashboard`;
    }

    public async getCoachByPersonId(personId: number) {
        const predicate = new MethodologyPredicate<Practitioner>("personId", "==", personId);
        const coaches = await lastValueFrom(this.commonDataService.getByPredicate(PractitionerBreezeModel, predicate));
        return ArrayUtilities.getSingleFromArray(coaches);
    }

    public async getSupplementaryDataForOrganisation(organisationId: number) {
        const predicate = new MethodologyPredicate<OrganisationSupplementaryData>("organisationId", "==", organisationId);
        const organisationSupplementaryData = await lastValueFrom(this.commonDataService.getByPredicate(OrganisationSupplementaryDataBreezeModel, predicate));
        return organisationSupplementaryData;
    }

    public async getAccountsForOrganisation(organisationId: number, forceRemote = false) {
        const predicate = new MethodologyPredicate<Account>("organisationId", "==", organisationId);
        const accounts: Account[] = await lastValueFrom(this.commonDataService.getWithOptions(AccountBreezeModel, predicate.getKey("accountsForOrganisation"), {
            navProperty: "accountModules",
            forceRemote,
        }));
        return accounts;
    }

    @Autobind
    public async getOrganisationsWithAccounts(forceRemote = false) {
        return await lastValueFrom(this.commonDataService.getWithOptions(OrganisationBreezeModel, "organisationsWithAccounts", {
            navProperty: "account",
            matchKeyOnly: true,
            forceRemote,
        }));
    }

    @Autobind
    public getPricingModels() {
        const productCode = AdaptClientConfiguration.IsCurrentEmbedApiBaseUri
            ? ProductCode.Embed
            : ProductCode.HQ;
        const predicate = new MethodologyPredicate<PricingModel>("productCode", "==", productCode);

        return this.commonDataService.getAll(PricingModelUserBreezeModel)
            .pipe(switchMap(() => this.commonDataService.getAll(PricingModelModuleBreezeModel)))
            .pipe(switchMap(() => this.commonDataService.getAll(ModuleBreezeModel)))
            .pipe(switchMap(() => this.commonDataService.getByPredicate(PricingModelBreezeModel, predicate)));
    }
}
