import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptCommonUserModule } from "@common/user/adapt-common-user.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptAuditModule } from "app/audit/audit.module";
import { DxDataGridModule, DxSelectBoxModule, DxTabPanelModule, DxTextBoxModule } from "devextreme-angular";
import { AdaptOrganisationsSharedModule } from "../organisations/organisations-shared.module";
import { AdaptToolsModule } from "../tools/tools.module";
import { ActivateAccountComponent } from "./activate-account/activate-account.component";
import { DisplayPersonDetailsComponent } from "./display-person-details/display-person-details.component";
import { LoginAsComponent } from "./login-as/login-as.component";
import { ManageMfaComponent } from "./manage-mfa/manage-mfa.component";
import { PeopleDashboardPageComponent } from "./people-dashboard-page/people-dashboard-page.component";
import { peopleDashboardPageRoute } from "./people-dashboard-page/people-dashboard-page.route";
import { PeopleListComponent } from "./people-list/people-list.component";
import { AdaptPeopleSharedModule } from "./people-shared.module";
import { PersonConnectionsComponent } from "./person-connections/person-connections.component";
import { PersonPageComponent } from "./person-page/person-page.component";
import { personPageRoute } from "./person-page/person-page.route";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        DxDataGridModule,
        DxSelectBoxModule,
        AdaptButtonModule,
        AdaptCommonUserModule,
        AdaptOrganisationsSharedModule,
        DxTextBoxModule,
        DxTabPanelModule,
        AdaptLoadingSpinnerModule,
        AdaptShellModule,
        AdaptToolsModule,
        AdaptTooltipModule,
        AdaptAuditModule,
        AdaptPeopleSharedModule,
    ],
    exports: [
        DisplayPersonDetailsComponent,
        PeopleListComponent,
    ],
    declarations: [
        DisplayPersonDetailsComponent,
        LoginAsComponent,
        PeopleListComponent,
        PeopleDashboardPageComponent,
        PersonConnectionsComponent,
        ResetPasswordComponent,
        PersonPageComponent,
        ActivateAccountComponent,
        ManageMfaComponent,
    ],
})
export class AdaptPeopleModule {
    public static readonly Routes = [
        ...peopleDashboardPageRoute.routes,
        ...personPageRoute.routes,
    ];
}
