<div class="card card-info">
    <div class="card-header">
        <h4 class="card-title">Manage two-factor authentication</h4>
    </div>
    <div class="card-body">
        <p>This will reset two-factor authentication for the given user. It will also reset their recovery codes.</p>

        @if (person?.methodologyUser) {
            <p>Two-factor status: <b>{{person!.methodologyUser!.twoFactorEnabled ? "enabled": "disabled"}}</b></p>
        } @else {
            <p>Two-factor status: <i>Loading...</i></p>
        }

        <button [adaptBlockingClick]="resetTwoFactor"
                [disabled]="!person || !person.methodologyUser"
                adaptButtonIcon="fal fa-fw fa-sync"
                class="btn btn-primary"
                data-test="submit-btn"
                type="submit">Reset two-factor</button>

        <div *ngIf="error"
             role="alert"
             class="alert alert-danger my-3">{{error}}</div>
    </div>
</div>
