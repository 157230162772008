import { Component, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { Account, AccountChargeState, AccountChargeStateDisplayMap } from "@common/ADAPT.Common.Model/account/account";
import { Invoice } from "@common/ADAPT.Common.Model/account/invoice";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { DxUtilities } from "@common/lib/utilities/dx-utilities";
import { PaymentProcessingService } from "@common/payment-processing/payment-processing.service";
import { ISimpleInvoice } from "@common/payment-processing/simple-invoice.interface";
import { DateFormats } from "@common/ux/date-formats";
import { DxDataGridComponent } from "devextreme-angular/ui/data-grid";

@Component({
    selector: "adapt-payments",
    styleUrls: ["./payments.component.scss"],
    templateUrl: "./payments.component.html",
})
export class PaymentsComponent implements OnChanges {
    @Input() public organisation!: Organisation;
    @ViewChild(DxDataGridComponent) public gridInstance?: DxDataGridComponent;

    public account?: Account;
    public invoices: Invoice[] = [];
    public latestInvoice?: Invoice;
    public nextInvoice?: ISimpleInvoice;

    public dateFormat = DateFormats.globalize.short;
    public AccountChargeState = AccountChargeState;
    public AccountChargeStateDisplayMap = AccountChargeStateDisplayMap;
    public errorMessage?: string;
    constructor(
        private paymentProcessingService: PaymentProcessingService,
    ) {
    }

    public async ngOnChanges(changes: SimpleChanges) {
        if (changes.organisation && changes.organisation.currentValue) {
            this.account = this.organisation.account;
            await this.paymentProcessingService.getInvoices(this.organisation.organisationId);
            this.latestInvoice = this.account && this.account.extensions.latestInvoice;

            this.getNextInvoice();
        }
    }

    public updateNextInvoice() {
        this.getNextInvoice();
    }

    public exportAllData() {
        DxUtilities.exportGridToExcel("invoices", this.gridInstance!.instance);
    }

    public showColumnChooser() {
        this.gridInstance!.instance.showColumnChooser();
    }

    private getNextInvoice() {
        if (this.account && this.account.extensions.isActive) {
            this.paymentProcessingService.getNextInvoice(this.organisation.organisationId)
                .subscribe({
                    next: this.setNextInvoice,
                    error: (error) => this.errorMessage = error,
                });
        }
    }

    @Autobind
    private setNextInvoice(invoice?: ISimpleInvoice) {
        this.nextInvoice = invoice;
    }
}
