<div *ngIf="errorMessage"
     role="alert"
     class="alert alert-danger"
     [textContent]="errorMessage"></div>
<div class="row">
    <div class="col-md-6">
        <!-- Last Invoice -->
        <div class="card card-success">
            <div class="card-header">
                <h3 class="card-title">Last Invoice
                    <span *ngIf="latestInvoice"
                          [ngClass]="['ms-3 badge badge-' + latestInvoice.status]">{{latestInvoice.status}}</span>
                    <span *ngIf="account"
                          class="ms-3 badge"
                          [ngClass]="account.chargeState === AccountChargeState.ChargeSuccess || account.chargeState ===  AccountChargeState.NoCharge ? 'badge-Paid' : 'badge-Pending'">
                        {{AccountChargeStateDisplayMap[account.chargeState]}}
                    </span>
                </h3>
            </div>
            <div class="card-body"
                 *adaptLoading="!organisation">
                <div *ngIf="latestInvoice && account">
                    <label for="latestInvoiceDate">Date Issued</label>
                    <p id="latestInvoiceDate"
                       [textContent]="latestInvoice.date | adaptDate"></p>

                    <label for="latestInvoiceAmount">Amount</label>
                    <p id="latestInvoiceAmount">
                        <span [textContent]="latestInvoice.total | currency:account!.currency?.code?.toUpperCase()"></span>
                        <span> (ex. GST)</span>
                    </p>

                    <label for="forPeriod">For Period</label>
                    <p id="forPeriod">{{latestInvoice.periodStartDate | adaptDate}} - {{latestInvoice.periodEndDate | adaptDate}}</p>
                </div>
                <div *ngIf="!latestInvoice">
                    No previous invoices
                </div>
                <div *ngIf="account?.remainingCreditDollars">
                    <label for="remainingCreditDollars">Remaining Credit</label>
                    <p id="remaingCreditDollars">
                        <span [textContent]="account!.remainingCreditDollars | currency:account!.currency?.code?.toUpperCase()"></span>
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-6">
        <!-- Next Invoice -->
        <div class="card card-info">
            <div class="card-header">
                <h3 class="card-title">Next Invoice</h3>
            </div>
            <div class="card-body"
                 *adaptLoading="!organisation">
                <div *ngIf="nextInvoice && account">
                    <label>Issue Date</label>
                    <p [textContent]="nextInvoice.date | adaptDate"></p>

                    <label>Estimated Amount</label>
                    <p>
                        <span [textContent]="nextInvoice.total | currency:account!.currency?.code?.toUpperCase()"></span>
                        <span> (ex. GST)</span>
                    </p>

                    <label for="forPeriod2">For Period</label>
                    <p id="forPeriod2">{{nextInvoice.periodStartDate | adaptDate}} - {{nextInvoice.periodEndDate | adaptDate}}</p>
                </div>
                <div *ngIf="!nextInvoice">
                    No upcoming invoice
                </div>
            </div>
        </div>
    </div>
</div>

<adapt-display-historical-invoices [organisation]="organisation"
                                   [displayAdvancedOptions]="true"></adapt-display-historical-invoices>
