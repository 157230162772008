<dx-tab-panel [items]="items"
              [selectedItem]="selectedTab"
              (onContentReady)="onContentReady()"
              (selectedItemChange)="onSelectedItemChanged($event)">
    <div *dxTemplate="let name of 'generalTemplate'">
        <adapt-display-person-details *adaptLoading="!person"
                                      [person]="person"></adapt-display-person-details>

        <adapt-person-connections [person]="person"></adapt-person-connections>
    </div>
    <div *dxTemplate="let name of 'accountTemplate'">
        <adapt-reset-password class="col-12 col-md-6 col-lg-4 col-xl-3"
                              [person]="person"></adapt-reset-password>
        <adapt-login-as [person]="person"></adapt-login-as>
        <adapt-activate-account [person]="person"></adapt-activate-account>
        <adapt-manage-mfa [person]="person"></adapt-manage-mfa>
    </div>
    <div *dxTemplate="let name of 'authTemplate'">
        <adapt-auth-audit *adaptLoading="!person"
                          [person]="person"></adapt-auth-audit>
    </div>
    <div *dxTemplate="let name of 'dataTemplate'">
        <adapt-data-audit *adaptLoading="!person"
                          [person]="person"
                          [showOrgFilter]="true"></adapt-data-audit>
    </div>
</dx-tab-panel>
