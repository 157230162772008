import { Component, Input } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { PeopleService } from "app/features/people/people.service";
import { StakeholderServicesService } from "app/features/tools/stakeholder-services.service";
import { lastValueFrom } from "rxjs";

@Component({
    selector: "adapt-manage-mfa",
    templateUrl: "./manage-mfa.component.html",
})
export class ManageMfaComponent {
    @Input() public person!: Person;

    public error?: string;

    constructor(
        private stakeholderService: StakeholderServicesService,
        private peopleService: PeopleService,
        private dialogService: AdaptCommonDialogService,
    ) {
    }

    @Autobind
    public async resetTwoFactor() {
        const shouldReset = await lastValueFrom(this.dialogService.openConfirmationDialogWithBoolean({
            title: "Reset two-factor authentication",
            message: `Are you sure you want to reset two-factor authentication for user <b>${this.person.fullName}</b>?`,
        }));

        if (shouldReset) {
            try {
                this.error = undefined;
                await this.stakeholderService.resetTwoFactor({ userId: this.person.userId });
                await lastValueFrom(this.dialogService.showMessageDialog("Two-factor reset", "Two-factor authentication has been reset for this user."));

                // update person info
                const person = await lastValueFrom(this.peopleService.getPersonById(this.person.personId, true));
                if (person) {
                    this.person = person;
                }
            } catch (e) {
                this.error = ErrorHandlingUtilities.getHttpResponseMessage(e);
            }
        }
    }
}
